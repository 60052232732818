<template>
  <div class="top-bar">
    <!-- : require('@/assets/logo-header.png') -->
    <img :src="logoUrl&&logoUrl!='' ? logoUrl :''" :class="'fenzhan_'+siteInfo.id" alt="logo" class="logo" v-if="type==1">
    <div class="back" @click="$router.back(-1)" v-if="type!=1"></div>
    <div class="title" v-if="type!=1">{{pageTitle}}</div>
    <nav class="nav" v-if="type==1">
      <router-link class="search" :to="'/search'"></router-link>
      <img class="user-avatar" v-if="userInfo" :src="userInfo.avatar?userInfo.avatar:require('@/assets/head-default-personal.png')" @click="$router.push('/userCenter')">
      <div class="log-ope" v-else @click="ShowMLoginModal">
        <template v-if="siteInfo.id!=108">
          <template v-if="reginState">
            登录/注册
          </template>
          <template v-else>登录</template>
        </template>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: 'TopBar',
  data(){
    return {
      isLogin: false, //是否登录
      logoUrl: this.logo,
      reginState:true,
      siteInfo:'',
    }
  },
  props: {
    type: Number, // 1.首页
    logo: {
      type: String,
      default: ''
    },
    pageTitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted() {
    if (!localStorage.getItem('userInfo')) {
      if (window.location.host == 'zgzxw.kskstudy.com') {
        if(this.$route.query.m) {
          this.getFsAppId()
        }
        if (this.$route.query.code) {
          this.getFsCode()
        }
      }
    }
    this.getWebsiteInfo()
    if(localStorage.getItem('siteInfo')){
      this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'))
    }
    if(window.location.host=='bqbzz.kskstudy.com' || window.location.host=='zgzxw.kskstudy.com' || window.location.host=='cxgzspx.kskstudy.com' || window.location.host=='ymjg.kskstudy.com' || window.location.host=='culrcx.kskstudy.com'|| window.location.host=='qnzgjxy.kskstudy.com'){
      this.reginState = false
    }else{
      this.reginState = true
    }
    this.GetUserInfo();
    if(localStorage.getItem('siteInfo')){
      this.logoUrl = JSON.parse(localStorage.getItem('siteInfo')).site_logo;
    }
     // 青海自动登录
    if (this.$route.query.authCode) {
      this.logBtnGrjy()
    }
      // 从中国劳动关系学院跳转过来的免登录功能begin
    let urlDataInfo = this.$route.query
    if(urlDataInfo.token && urlDataInfo.url){
      this.autoLoginForLdgx()
      // if(!this.userInfo){
      //   // 未登录的情况下
      //   this.autoLoginForLdgx()
      // }else{
      //   // 已登录的情况下
      //   if(this.userInfo.phone==urlDataInfo.phone){
      //     window.location.href = urlDataInfo.url
      //   }else{
      //     this.autoLoginForLdgx()
      //   }
      // }
    }

    // 从中国劳动关系学院跳转过来的免登录功能end
    let urlQuery = this.$route.query;
    //判断
    if(urlQuery.token && urlQuery.phone && urlQuery.nickname){
      if(!this.userInfo || this.userInfo.phone != new Buffer(urlQuery.phone, 'base64').toString('utf8')){
        let data ={
          token: urlQuery.token,
          phone: urlQuery.phone,
          nickname: urlQuery.nickname,
        }
        // 'js.1659434272.xVJKdFCcLM'
        // data = {
        //   token: 'js.1659434272.86f152a3f0781ece99a32b74d1468560',
        //   phone: 'MTU4MTAyMjEwOTM=',
        //   nickname: 'Y2hvbmc=',
        // }

        // var decryptStr = new Buffer(urlQuery.nickname, 'base64').toString('utf8')
        

        this.$axios.post(`/v1/login/autoLogin`, data).then((res) => {
          if(res.code==0){
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.GetUserInfo();
            this.$router.push('/')
            window.location.reload()
            this.$axios.post(`/v1/px/loginRecord`, {}, {useLog: true})//登录记录
            if(urlQuery.type==2){
              this.$router.push('/userCenter/learning');
            }
          }else{
            this.ShowTipModal({
              text: res.msg,     //提示弹窗内容
            })
          }
        });
      }
    }

    // if(this.siteInfo.id == 108){ //塞上工匠
    //   const token = this.$route.query.token;
    //   if(token){
    //     localStorage.clear();
    //     this.$axios.post(`/v1/login/nXAutoLogin`, { token }).then((res) => {
    //       if(res.code==0){
    //         localStorage.setItem("auth", JSON.stringify(res.data.auth));
    //         localStorage.setItem("user", JSON.stringify(res.data.user));
    //         localStorage.setItem("userInfo", JSON.stringify(res.data.user));
    //         this.GetUserInfo();
    //         this.$router.push('/')
    //         window.location.reload()
    //       }
    //     })
    //   }
    // }
  },
  methods:{
    ...mapMutations(['ShowMLoginModal', 'GetUserInfo', 'ShowTipModal']), //登录弹窗
    // 从飞书跳转过来获取appid
    getFsAppId() {
      this.$axios.post(`/v1/login/feishuAutoLoginToZgzxwAppid`, {}).then((res) => { 
        if (res.code == 0) {
          window.location.href = `https://accounts.feishu.cn/open-apis/authen/v1/authorize?client_id=${res.data.appid}&redirect_uri=https://zgzxw.kskstudy.com/`;
        }
      });
    },
    // 从飞书跳转过来获取code 
    getFsCode() {
      this.$axios.post(`/v1/login/feishuAutoLoginToZgzxw`, {code: this.$route.query.code}).then((res) => {
        if (res.code == 0) {
          localStorage.setItem("auth", JSON.stringify(res.data.auth));
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          this.GetUserInfo();
          this.$forceUpdate()
          this.$router.push('/')
          this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }).then(() => {
            // this.$router.replace('/')
          });
        } else {
            this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    autoLoginForLdgx(){
       let urlDataInfo = this.$route.query
      let data = {
          token: urlDataInfo.token,
        };
        this.$axios.post(`/v1/login/autoLoginForLdgx`, data).then((res) => {
          if (res.code == 0) {
            localStorage.setItem("auth", JSON.stringify(res.data.auth));
            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            this.GetUserInfo();
            window.location.href = urlDataInfo.url
            this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }); //登录记录
            // if (urlQuery.type == 2) {
            //   this.$router.push("/userCenter/learning");
            // }
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
    },
    // 自动登录
    logBtnGrjy() {
      this.$axios.post(`/v1/qhAutoLogin`, { authCode: this.$route.query.authCode }).then(res => {
        if (res.code == 0) {
          localStorage.setItem("auth", JSON.stringify(res.data.auth));
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("userInfo", JSON.stringify(res.data.user));
          this.$router.push('/')
          window.location.reload()
          this.$axios.post(`/v1/px/loginRecord`, {}, { useLog: true }).then(() => {
            
          });
        } else {
            this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 1 }).then((res) => {
         this.logoUrl = res.data.site_info.site_logo
         this.siteInfo = res.data.site_info
         if(this.siteInfo.id == 108){ //塞上工匠
          const token = this.$route.query.token;
          if(token){
            localStorage.clear();
            this.$axios.post(`/v1/login/nXAutoLogin`, { token }).then((res) => {
              if(res.code==0){
                localStorage.setItem("auth", JSON.stringify(res.data.auth));
                localStorage.setItem("user", JSON.stringify(res.data.user));
                localStorage.setItem("userInfo", JSON.stringify(res.data.user));
                this.GetUserInfo();
                this.$forceUpdate()
                this.$router.push('/')
                // window.location.reload()
              }
            })
          }
        }
         localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.log-ope{
  padding-right: 10px;
  font-weight: bold;
  font-size: 28px;
}
.top-bar{
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: 888;
}
.logo{
  width: auto;
  height: 56px;
  float: left;
  margin: 16px 0 0 16px;
  &.fenzhan_107{
    height: 88px;
    margin: 0 0 0 16px;
  }
}
.nav{
  display: flex;
  align-items: center;
  float: right;
}
.search,
.user{
  display: block;
  width: 88px;
  height: 88px;
  background: url(~@/assets/search-grey-m-ico.png) no-repeat center center;
  background-size: auto 44px ;
}
.user{
  background: url(~@/assets/user-m-ico.png) no-repeat center center;
  background-size: auto 44px ;
}
.user-avatar{
  width: 58px;
  height: 58px;
  border-radius: 50%;
  margin-right: 20px;
  &.noavatar{
    width: auto;
    height: 48px;
    border-radius: 0;
  }
}
.back{
  width: 88px;
  height: 88px;
  background: url(~@/assets/arrows.png) center center no-repeat;
  background-size: 44px;
  transform:rotate(90deg);
  float: left;
}
.title{
  width: 574px;
  height: 88px;
  line-height: 88px;
  font-size: 36px;
  color: #333;
  font-weight: bold;
  text-align: center;
  float: left;
}
</style>
